.container {
  height: 100vh;
  max-width: 100%;
  padding: 5;
  margin: 0;
}

.controls {
  align-items: flex-end;
}

.header {
  background-image: linear-gradient(to right, #0F6CBD, white);
}

.title {
  color: white;
}

.subtitle {
  color: rgba(171, 143, 99, 1);
  font-size: 1.75rem;
  font-weight: bold;
}

.description {
  font-size: 1.25rem;
}